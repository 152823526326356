import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";


import image1 from "../../logos/photos/image21.jpg"
import image2 from "../../logos/photos/image22.jpg"
import image3 from "../../logos/photos/image23.jpeg"

import { useNavigate } from 'react-router-dom';

export default function RSS() {
  const navigate = useNavigate();
 
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-12">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#095a4f'}}>
                New Research Explores Integration of PALPABLE Technology with Robotic Surgery Systems
             </h3>
             <p>A groundbreaking master's thesis conducted at the <a href='https://www.linkedin.com/school/universidad-politecnica-de-madrid/'>Universidad Politécnica de Madrid</a> has laid the foundation for integrating PALPABLE's innovative haptic feedback technology into robotic-assisted surgery (RAS) systems, specifically focusing on <a href='https://www.medtronic.com/covidien/en-gb/robotic-assisted-surgery/hugo-ras-system.html'>Medtronic's Hugo™ RAS</a> platform.

             </p>
             <p>The research, carried out by <a href='https://www.linkedin.com/in/annapellisetintore/'>Anna Pellisé Tintoré</a> under the supervision of <a href='https://www.linkedin.com/in/claudia-navarro-argence/'>Clàudia Navarro</a> and Blanca Larraga, provides a comprehensive framework for incorporating PALPABLE's haptic feedback capabilities into existing surgical robotics infrastructure. This integration strategy represents a significant step forward in addressing one of the major limitations of robotic surgery: the lack of tactile feedback for surgeons.
             </p>
             <h5>Validated Clinical Need</h5>
           <p>Through extensive interviews with surgeons across various specialties, the study confirmed the critical importance of haptic feedback in surgical procedures. Whilst traditional laparoscopy allows for some tactile sensation, robotic surgery currently offers none - a limitation that the PALPABLE project directly addresses. The research revealed that surgeons rated the loss of haptic feedback in robotic surgery at 4.63 out of 5, compared to just 2.32 for traditional laparoscopy, underlining the urgent need for this technological advancement.
           </p>
           <h5>Integration Strategy</h5>
           <p>
           The research evaluated multiple integration scenarios and proposed a removable modality for the PALPABLE device, allowing it to be attached to robotic arms when haptic feedback is needed. This approach aligns with current surgical workflows and received unanimous support from interviewed surgeons.
           </p> 
           <p>Key recommendations include:</p>
           <ul style={{listStyleType:'circle'}}>
            <li>Utilising existing endoscope adapters for physical connection to robotic arms
            </li>
            <li>Implementing voice control systems for adjusting the device's flexibility settings
            </li>
            <li>Strategic placement of external components within the operating theatre to ensure seamless workflow integration
            </li>
           </ul>
           <br/>
           <h5>Looking Forward</h5>
           <p>Whilst the research focussed primarily on integration with the Hugo RAS system, we aim for the PALPABLE tool to be compatible with other robotic surgery platforms, and the framework developed could potentially be adapted for them.
           </p>
           <p>This research provides valuable insights for the PALPABLE consortium as we continue developing our technology. The detailed analysis of surgeon preferences and technical requirements will help guide our development process, ensuring that the final product meets the real-world needs of surgical teams.</p>
               </div>
            </div>
           
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
