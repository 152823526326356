import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";


import image1 from "../../logos/photos/image21.jpg"
import image2 from "../../logos/photos/image22.jpg"
import image3 from "../../logos/photos/image23.jpeg"

import { useNavigate } from 'react-router-dom';

export default function Showcases() {
  const navigate = useNavigate();
 
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-12">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#095a4f'}}>
                Research Showcases Capabilities of PALPABLE Optical Fiber-Based Force Sensor
             </h3>
             <p>The PALPABLE consortium has developed an innovative optical fiber-based force sensor that could significantly improve 
              tactile feedback during minimally invasive surgery (MIS), according to new research published in 
              <a jref='https://www.frontiersin.org/journals/robotics-and-ai'>Frontiers in Frontiers in Robotics and AI</a>. This sensor addresses the challenge surgeons face when assessing tissue characteristics in confined spaces, where direct touch is limited. In traditional open surgery, palpation—using touch to identify abnormalities—helps clinicians detect lumps, variations in tissue stiffness, or other potential concerns. However, in MIS, the restricted access significantly reduces tactile feedback, often complicating the surgeon’s ability to evaluate tissue characteristics.
             </p>
             <p>
             The novel sensor design features an abraded optical fiber embedded within a soft silicone dome. 
             As pressure is applied, the fiber within the dome bends, causing a reduction in light intensity, 
             which can be measured and correlated to the applied force. The dome’s internal pressure can also be adjusted, 
             which changes the sensor’s stiffness. This tunable feature allows the force range and sensitivity of the sensor 
             to be adapted to different surgical requirements.</p>
             <p>The research was authored by <a href='https://www.linkedin.com/in/abubakardawood/'>Abu Bakar Dawood</a>, 
              along with <a href='https://www.qmul.ac.uk/eecs/people/profiles/althoeferkaspar.html'>Kaspar Althoefer</a>, 
              <a href='https://www.linkedin.com/in/chavalivamsikrishna/'> Vamshi Krishna Chavali</a> and 
              Thomas Mack of partner <a href='https://www.qmul.ac.uk/'>Queen Mary University of London</a>, Zhenyu Zhang,
               <a href='https://www.linkedin.com/in/martin-angelmahr-032564b2/'> Martin Angelmahr</a> from partner 
               <a href='https://www.linkedin.com/in/martin-angelmahr-032564b2/'> Fraunhofer Heinrich-Hertz-Institut</a>, and 
               <a href='https://profiles.sussex.ac.uk/p525192-hareesh-godaba'> Hareesh Godaba</a> from 
               <a href='https://www.sussex.ac.uk/'> University of Sussex</a>.</p>
          <p>In tests, the sensor demonstrated promising accuracy in measuring a range of forces and stiffnesses. For example, maximum force measurements were recorded at 5.02 N, 6.70 N, and 8.83 N, with internal dome pressures set to 0 psi, 0.5 psi, and 1 psi, respectively. Additionally, the sensor successfully estimated stiffness in a range of tissue phantoms, accurately covering elastic moduli between 8.58 kPa and 165.32 kPa with minimal error. This adaptability in sensitivity and force range provides surgeons with valuable, real-time feedback, potentially improving MIS precision. </p>
          <p>The team aims to refine the sensor further by incorporating a fabric mesh to prevent excess bulging under pressure and reducing its size to fit through standard trocar ports, making it an ideal tool for MIS. PALPABLE’s promising technology could significantly enhance safety, accuracy, and tactile precision, ultimately improving surgical outcomes.         
          </p>
          <p>You can read the research <a href='https://www.frontiersin.org/journals/robotics-and-ai/articles/10.3389/frobt.2024.1489884/full?utm_source=F-NTF&utm_medium=EMLX&utm_campaign=PRD_FEOPS_20170000_ARTICLE'>here</a>.</p>
                </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
               
              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
